import {post, get} from 'ys-admin'

/**
 *  获取报告信息
 * @param {Object} params
 * @returns {Object}
 */
export const getReportInfo = function (params) {
  return get('/report/GetInfo', params)
};
/**
 *  获取报告信息
 * @param {Object} params
 * @returns {Object}
 */
export const getReportScoreInfo = function (params) {
  return get('/report/GetScore', params)
};
/**
 *  获取报告详情
 * @param {Object} params
 * @returns {Object}
 */
export const getReportDetail = function (params) {
  return get('/report/GenInfo', params)
};
/**
 *  获取报告列表
 * @param {Object} params
 * @returns {Object}
 */
export const getReportList = function (params) {
  return get('/report/List', params)
};

/**
 *  新增/修改报告
 * @param {Object} params
 * @returns {Object}
 */
export const addReport = function (params) {
  return post('/report/Add', params)
};

/**
 *  新增/修改报告
 * @param {Object} params
 * @returns {Object}
 */
export const reportCommit = function (params) {
  return post('/report/Commit', params)
};

/**
 *  新增/修改报告
 * @param {Object} params
 * @returns {Object}
 */
export const reportCommitCheck = function (params) {
  return post('/report/CommitCheck', params)
};

/**
 *  新增/修改报告
 * @param {Object} params
 * @returns {Object}
 */
export const reportEdit = function (params) {
  return post('/report/Add', params)
};

/**
 * 查询报告模板数据
 * @param {Object} params
 * @returns {Object}
 */
export const templateList = function (params) {
  return get('/template/List', params)
};

/**
 * 生成及保存报告
 * @param {Object} params
 * @returns {Object}
 */
export const genAndSaveReport = function (params) {
  return post('/report/GenAndSaveReport', params)
};